<template>
  <base-view title="Usuarios, roles y permisos" icon="key">
    <!-- Account page navigation-->
    <nav class="my-nav nav nav-borders">
      <router-link
        v-for="route in usersAndPermissionsViews"
        :key="route.id"
        :to="{ name: route.name, params: { tabActive: route.name } }"
        :class="getActiveClass(route.path)"
        class="nav-link my-active ml-0"
      >
        <check-authorization :requiresAuthorizations="route.meta.requiresPermissions" hide>
          {{ route.meta.title }}
        </check-authorization>
      </router-link>
    </nav>

    <hr class="mt-0 mb-4" />

    <!-- Main view -->
    <component :is="selectedComponent"></component>
  </base-view>
</template>

<script>
//
// Este componente maneja dinámicamente los 3 conceptos
// Usuarios, Roles y Permisos.
//
// Este es el componente padre que renderiza un componente
// dinámico según sea el caso de pestaña que se ha de seleccionar.
//
import Users from '@/components/Users/Users'
import Roles from '@/components/Roles/Roles'
import Permissions from '@/components/Permissions/Permissions'
import { usersAndPermissionsViews } from '@/router/routes'

export default {
  name: 'UsersRolesPermissionsView',

  components: {
    //
    // Componentes dinámicos
    Users,
    Roles,
    Permissions
  },

  props: {
    tabActive: {
      type: String,
      required: true,
      validator: function (value) {
        return ['users', 'roles', 'permissions'].indexOf(value) !== -1
      }
    }
  },

  data () {
    return {
      usersAndPermissionsViews,
      selectedComponent: this.tabActive
    }
  },

  watch: {
    $route (route) {
      this.selectedComponent = route.params.tabActive
    }
  },

  methods: {
    getActiveClass (path) {
      return this.$route.path === path ? 'active' : ''
    }
  }
}
</script>
