<template>
  <div>
    <b-button
      class="btn-sm mr-3 mb-2 mb-sm-0 btn-outline-green"
      variant="white"
      @click="showModal()"
    >
      Nueva contraseña
    </b-button>

    <b-modal
      v-model="modal"
      centered
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
    >
      <template #modal-title>
        <span>Nueva contraseña</span>
      </template>

      <!-- if email is not valid show a text warning -->
      <template v-if="!$isEmailValid(email)">
        <b-alert variant="danger" show>
          El email no es válido
        </b-alert>
      </template>

      <!-- Contraseña -->
      <FormulateInput
        name="password"
        label="Contraseña"
        required
        autocomplete="on"
        validation="bail|required|min:10,length|max:32,length|noRepeatingCharacters|notConsecutiveNumbers|atLeastOneMayus|atLeastOneSymbol|atLeastOneNumber"
        :validation-rules="$getPasswordValidationRules"
        :validation-messages="$getPasswordValidationMessages"
      >
        <template #element>
          <div class="input-group">
            <div class="input-group-prepend">
              <b-button @click="getGeneratedPassword" variant="primary">Generar</b-button>
            </div>
            <input
              :value="password"
              :type="showingPassword.value ? 'text' : 'password'"
              readonly
              class="form-control"
            />
            <div class="input-group-append">
              <b-button
                @click="toggleShowPassword"
                variant="danger"
                size="sm"
                v-b-tooltip.hover
                :title="showingPassword.text"
              >
                <b-icon-eye v-if="showingPassword.value"></b-icon-eye>
                <b-icon-eye-slash v-else></b-icon-eye-slash>
              </b-button>
            </div>
          </div>
        </template>
      </FormulateInput>

      <template #modal-footer>
        <div class="w-100">
          <x-form-footer-buttons
            @on-update="sendPassword"
            @on-cancel="hideModal"
            is-editing
            :disabled="isLoading || !$isEmailValid(email)"
            btn-update-type="Crear"
            btn-cancel-title="Cerrar"
            btn-update-text="Enviar"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
const showingPassword = () => ({
  value: false,
  text: 'Mostrar contraseña',
  type: 'password'
})

export default {
  name: 'XPasswordResetButton',

  props: {
    userId: {
      type: Number,
      required: true
    },

    email: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      modal: false,

      password: null,

      showingPassword: showingPassword(),

      isLoading: false
    }
  },

  methods: {
    showModal () {
      this.modal = true
    },

    toggleShowPassword () {
      this.showingPassword.value = !this.showingPassword.value
      this.showingPassword.text = this.showingPassword.value
        ? 'Ocultar contraseña'
        : 'Mostrar contraseña'
      this.showingPassword.type = this.showingPassword.value ? 'text' : 'password'
    },

    getGeneratedPassword () {
      const password = this.$onGeneratePassword()
      this.password = password
      this.$forceUpdate()
    },

    async sendPassword () {
      if (this.password) {
        this.isLoading = true
        const { error, message } = await this.$store.dispatch('userModule/resetPassword', {
          id: this.userId,
          password: this.password
        })

        if (error) {
          this.$notify({ error, message }, 'Nueva contraseña')
          this.isLoading = false
          return
        }

        this.hideModal()
        this.$notify({ message }, 'Nueva contraseña')
      } else {
        this.$notify({ error: true, message: 'Debe generar una contraseña' }, 'Nueva contraseña')
      }
    },

    hideModal () {
      this.modal = false
      this.password = null
      this.isLoading = false
      this.showingPassword = showingPassword()
    }
  }
}
</script>
