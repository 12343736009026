<template>
  <div>
    <slot name="activator" v-bind:onShowModal="onShowModal">
      <b-button @click="onShowModal" variant="success" size="sm">Crear</b-button>
    </slot>

    <b-modal
      v-model="modal"
      centered
      size="lg"
      scrollable
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      dialog-class="x-modal-md"
    >
      <template #modal-title>
        <span :class="isEditingUser ? 'text-danger' : ''">{{ showFormTitle }}</span>
      </template>

      <x-alert-with-errors
        :error="response.error"
        :title="response.message"
        :errors="response.errors"
      />

      <b-row v-if="isEditingUser">
        <b-col class="d-flex flex-row">
          <b-badge variant="dark" class="mb-3 mr-2" v-text="getLastAccessText"></b-badge>
          <b-badge variant="success" class="mb-3 mr-2" v-text="getCreationDate"></b-badge>
          <b-badge variant="warning" class="mb-3" v-text="getModificationDate"></b-badge>
        </b-col>
      </b-row>

      <b-overlay :show="isLoading">
        <template #overlay>
          <div class="text-center">
            <loading message="Procesando información, por favor espere..." />
          </div>
        </template>

        <b-card :class="{ isLoading }">
          <FormulateForm
            ref="userForm"
            name="userForm"
            v-model="formValues"
            :errors="response.errors"
            @submit="submit"
          >
            <b-row>
              <!-- Nombre -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="nombres"
                  label="Nombre(s)"
                  required
                  type="text"
                  validation="bail|required"
                  validation-name="Nombre(s)"
                />
              </b-col>

              <!-- Apellidos -->
              <b-col cols="12" md="6">
                <FormulateInput
                  name="apellidos"
                  label="Apellidos"
                  required
                  type="text"
                  validation="bail|required"
                  validation-name="Apellido paterno"
                />
              </b-col>
            </b-row>

            <!-- Profesion -->
            <FormulateInput
              name="profesion"
              label="Profesión"
              type="text"
              placeholder="Lic., Ing., C.P."
            />

            <!-- Fecha ingreso -->
            <FormulateInput
              name="fecha_ingreso"
              label="Fecha de ingreso"
              type="date"
              required
              validation="required"
              validation-name="Fecha de ingreso"
            />

            <!-- Email -->
            <FormulateInput
              name="email"
              label="Correo electrónico"
              required
              type="email"
              validation="bail|required|email"
              validation-name="Correo electrónico"
              error-behavior="live"
            >
              <template #element>
                <b-input-group>
                  <input
                    v-model="formValues.email"
                    type="email"
                    placeholder="usuario@correo.com"
                    class="form-control"
                  />
                  <template #append v-if="isEditingUser">
                    <b-button
                      @click="sendResetPasswordEmail(formValues.email)"
                      size="sm"
                      :variant="recoverPassword.variant"
                      v-b-tooltip.hover
                      :disabled="recoverPassword.sending"
                      :title="
                        recoverPassword.sending ? 'Enviando correo...' : recoverPassword.message
                      "
                    >
                      <b-icon-envelope></b-icon-envelope>
                    </b-button>
                  </template>
                </b-input-group>
              </template>
            </FormulateInput>

            <!-- Contraseña -->
            <FormulateInput
              v-if="!isEditingUser"
              name="password"
              label="Contraseña"
              required
              autocomplete="on"
              error-behavior="live"
              validation="bail|required|min:10,length|max:32,length|noRepeatingCharacters|notConsecutiveNumbers|atLeastOneMayus|atLeastOneSymbol|atLeastOneNumber"
              :validation-rules="$getPasswordValidationRules"
              :validation-messages="$getPasswordValidationMessages"
            >
              <template #element>
                <div class="input-group">
                  <div class="input-group-prepend">
                    <b-button @click="getGeneratedPassword" variant="primary">Generar</b-button>
                  </div>
                  <input
                    :value="formValues.password"
                    :type="showingPassword.value ? 'text' : 'password'"
                    readonly
                    class="form-control"
                  />
                  <div class="input-group-append">
                    <b-button
                      @click="toggleShowPassword"
                      variant="danger"
                      size="sm"
                      v-b-tooltip.hover
                      :title="showingPassword.text"
                    >
                      <b-icon-eye v-if="showingPassword.value"></b-icon-eye>
                      <b-icon-eye-slash v-else></b-icon-eye-slash>
                    </b-button>
                  </div>
                </div>
              </template>
            </FormulateInput>

            <!-- Lista de roles combinados con los roles del sistema y del usuario -->
            <check-authorization :requiresAuthorizations="['listar roles']">
              <check-list
                ref="checklist"
                title="Roles"
                :fullList="getSystemRoles"
                :currentList="currentRoles"
                @on-selecting="onSelectedRoles"
                :init-all-selected="
                  Boolean(formValues.roles_checklist) && formValues.roles_checklist.length > 0
                "
              />
            </check-authorization>
          </FormulateForm>
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <!-- Enviar para crear o actualizar -->
        <div class="w-100">
          <x-form-footer-buttons
            :disabled="$refs.userForm ? $refs.userForm.hasErrors : false"
            :loading="isLoading"
            :isEditing="isEditingUser"
            :isCreating="!isEditingUser"
            @on-cancel="resetForm"
            @on-create="submit"
            @on-update="submit"
          ></x-form-footer-buttons>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import CheckList from '@/components/Shared/CheckList'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import 'vue-select/dist/vue-select.css'

const formValues = () => ({
  nombres: '',
  apellidos: '',
  profesion: '',
  fecha_ingreso: null,
  email: '',
  password: null,
  roles: [],
  roles_checklist: []
})

const showingPassword = () => ({
  value: false,
  text: 'Mostrar contraseña',
  type: 'password'
})

const recoverPassword = () => ({
  sending: false,
  message: 'Enviar email para recuperación de contraseña',
  variant: 'warning'
})

const response = () => ({
  error: false,
  message: '',
  errors: {}
})

export default {
  name: 'UserForm',

  components: {
    CheckList
  },

  props: {
    value: {
      type: Boolean,
      required: true
    }
  },

  created () {
    //
    // Obtiene todos los roles del sistema
    this.$store.dispatch('roleModule/getSystemRoles')
  },

  data () {
    return {
      modal: this.value,

      isLoading: false,

      formValues: formValues(),

      currentRoles: [],

      showingPassword: showingPassword(),

      recoverPassword: recoverPassword(),

      response: response()
    }
  },

  computed: {
    //
    // User module
    ...mapState('userModule', ['editingUser']),
    ...mapGetters('userModule', ['getRolesOfEditingUser', 'isEditingUser']),

    //
    // Role module
    ...mapGetters('roleModule', ['getSystemRoles']),

    showFormTitle () {
      return this.isEditingUser
        ? `Editando usuario: '${this.editingUser.persona.nombre_completo}'`
        : 'Crear nuevo usuario'
    },

    getLastAccessText () {
      if (!this.isEditingUser) return ''

      return this.editingUser.ultimo_acceso === 'Sin registro de ingreso'
        ? this.editingUser.ultimo_acceso
        : `Último acceso: ${this.$formatDate(this.editingUser.ultimo_acceso)}`
    },

    getCreationDate () {
      if (!this.isEditingUser) return ''

      return `Creación: ${this.$customFormatDate(this.editingUser.fecha_creacion, 'DD/MMMM/YYYY')}`
    },

    getModificationDate () {
      if (!this.isEditingUser) {
        return ''
      }

      return `Modificación: ${this.$customFormatDate(
        this.editingUser.ultima_modificacion,
        'DD/MMMM/YYYY'
      )}`
    }
  },

  watch: {
    isEditingUser: {
      handler (value) {
        this.recoverPassword = recoverPassword()

        if (value) {
          const { id, email, persona, empleado, roles_checklist } = this.editingUser
          this.formValues.id = id
          this.formValues.nombres = persona.nombres
          this.formValues.apellidos = persona.apellidos
          this.formValues.profesion = empleado.profesion

          //
          // Fecha de ingreso
          this.formValues.fecha_ingreso = moment(empleado.fecha_ingreso).format('YYYY-MM-DD')

          //
          // Email
          this.formValues.email = email

          //
          // Se añade la lista de roles que posee
          this.formValues.roles_checklist = roles_checklist
          this.currentRoles = roles_checklist

          return
        }

        this.resetForm()
      }
    },

    value: {
      immediate: true,
      handler (value) {
        this.modal = value
        this.getGeneratedPassword()
      }
    },

    modal: {
      immediate: true,
      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  methods: {
    /**
     * Envía el formulario.
     *
     * Envía el formulario para su creación o su actualización.
     * Dependiendo si la variable "isEditingUser" es verdadera actualizará
     * si no es así entonces mandará crear.
     */
    async submit () {
      this.response = response()
      this.prepareInput()

      this.isLoading = true
      const { error, message, data } = this.isEditingUser
        ? await this.updateUser(this.formValues)
        : await this.createUser(this.formValues)
      this.isLoading = false

      this.$notify({ error, message }, 'Envío de formulario')

      this.response.error = error
      this.response.message = message
      this.response.errors = error ? data.errors : {}

      if (!error) this.resetForm()
    },

    async createUser (form) {
      return await this.$store.dispatch('userModule/createUser', form)
    },

    async updateUser (form) {
      return await this.$store.dispatch('userModule/updateUser', {
        id: form.id,
        payload: form
      })
    },

    /**
     * Prepara los datos antes de enviarlos al backend.
     *
     * Prepara los datos necesarios como la fecha o los roles y permisos que
     * se deben procesar en el backend.
     */
    prepareInput () {
      this.formValues.fecha_ingreso = moment(this.formValues.fecha_ingreso).format('YYYY-MM-DD')

      //
      // Obtiene los roles seleccionados
      this.getSelectedRoles()
    },

    /**
     * Si el usuario actual no tiene permisos para listar los roles,
     * entonces se toman los roles por defecto del usuario que está editando.
     */
    getSelectedRoles () {
      if (!this.$can(['listar roles'])) {
        this.formValues.roles = this.getRolesOfEditingUser
        return
      }

      //
      // Se necesita mandar los roles, en formato de array, únicamente los nombres.
      this.formValues.roles = this.formValues.roles_checklist
        ? this.formValues.roles_checklist.map(el => el.name)
        : []
    },

    onSelectedRoles (data) {
      this.formValues.roles_checklist = data
    },

    getGeneratedPassword () {
      const password = this.$onGeneratePassword()
      this.formValues.password = password
      this.$forceUpdate()
    },

    toggleShowPassword () {
      this.showingPassword.value = !this.showingPassword.value
      this.showingPassword.text = this.showingPassword.value
        ? 'Ocultar contraseña'
        : 'Mostrar contraseña'
      this.showingPassword.type = this.showingPassword.value ? 'text' : 'password'
    },

    resetForm () {
      document.activeElement.blur()
      this.$store.commit('userModule/setUserToEdit', null)
      this.$formulate.reset('userForm')
      this.formValues = formValues()
      this.response = response()
      this.showingPassword = showingPassword()
      this.currentRoles = Array.from([])
      this.recoverPassword = recoverPassword()
      this.$refs.checklist.clearForm()

      this.modal = false
    },

    async sendResetPasswordEmail (email) {
      this.recoverPassword.sending = true

      const getval = await this.$store.dispatch('authModule/recoverPassword', email)

      this.$notify(getval, 'Envio de email')

      this.recoverPassword.sending = false
      this.recoverPassword.message = getval.error
        ? 'Reintentar enviar correo de recuperación de contraseña'
        : getval.message
      this.recoverPassword.variant = getval.error ? 'danger' : 'success'
    },

    onInputFechaIngreso (value) {
      this.formValues.fecha_ingreso = moment(value).format('YYYY-MM-DD')
    },

    onShowModal () {
      this.modal = true
      this.$emit('input', this.modal)
    }
  }
}
</script>

<style>
.required_form {
  color: red;
}
.isLoading {
  max-height: 400px !important;
  overflow: hidden;
}
</style>
