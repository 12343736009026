import { render, staticRenderFns } from "./XPasswordResetButton.vue?vue&type=template&id=5f31804c"
import script from "./XPasswordResetButton.vue?vue&type=script&lang=js"
export * from "./XPasswordResetButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports