<template>
  <div>
    <!-- Table -->
    <b-table
      id="usersTable"
      v-if="getAvailableList"
      :fields="fields"
      :items="getUserList"
      striped
      bordered
      responsive
      hover
      stacked="lg"
      show-empty
      small
      :per-page="perPage"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :filter-function="filterFunction"
      @filtered="onFiltered"
      empty-filtered-text="No se encontraron usuarios con el criterio de búsqueda escrito"
    >
      <!-- Eliminado -->
      <template #cell(is_deleted)="row">
        <b-badge v-if="row.item.is_deleted" variant="danger">No</b-badge>
        <b-badge v-else variant="light">Sí</b-badge>
      </template>

      <template #cell(persona.nombre_completo)="row">
        <filtered-row :value="row.item.persona.nombre_completo" :filter="filter"/>
      </template>

      <!-- Email -->
      <template #cell(email)="row">
        <filtered-row :value="row.item.email" :filter="filter"/>
      </template>

      <!-- Roles -->
      <template #cell(roles_checklist)="data">
        <b-badge
          v-for="role in data.item.roles_checklist"
          :key="role.id"
          :variant="getRoleVariant(role.name)"
          class="mr-1"
        >
          {{ role.name }}
        </b-badge>
        <span v-if="data.item.roles_checklist.length <= 0" class="text-danger">
          Sin roles asignados
        </span>
      </template>

      <!-- Detalle -->
      <template #cell(show_details)="row">
        <b-button
          @click="row.toggleDetails"
          variant="light"
          class="btn-sm mb-2 btn-block btn-outline-black"
        >
          Detalles
        </b-button>

        <check-authorization :requiresRoles="['admin']" class="w-100" v-if="!row.item.is_deleted">
          <b-button
            class="btn-sm mb-2 btn-block btn-outline-indigo"
            variant="light"
            @click="viewAsUser(row.item.id)"
            v-if="!isAuthUserOrCloneUser(row.item.id)"
          >
            Ver como
          </b-button>
        </check-authorization>
      </template>

      <!-- Acciones -->
      <template #cell(acciones)="row">
        <div class="d-flex justify-content-center" v-if="!row.item.is_deleted">
          <x-password-reset-button
            :user-id="row.item.id"
            :email="row.item.email"
          >
          </x-password-reset-button>
          <u-d-controls
            :id="row.item.id"
            editPath="userModule/getUserToEdit"
            deletePath="userModule/deleteUser"
            :editPermissions="['editar usuarios']"
            :deletePermissions="['eliminar usuarios']"
            :hideDeleteAction="isAuthUserOrCloneUser(row.item.id)"
            @on-got-item="emitOnGotItem"
          ></u-d-controls>
        </div>
      </template>

      <!-- Acciones -->
      <template #row-details="row">
        <b-card
          class="mb-1"
          border-variant="primary"
          header-bg-variant="primary"
          header-text-variant="white"
          header="Detalle"
          no-body
        >
          <b-card-body>
            <b-row class="mb-2">
              <b-col cols="12" md="6">
                <b-card
                  header="Datos del usuario"
                  no-body
                  border-variant="primary"
                  class="mb-4"
                  style="height: 500px; overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item>
                      <b>Razón social:</b>
                      {{ row.item.persona.nombre_completo }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Email:</b>
                      {{ row.item.email }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Fecha de ingreso:</b>
                      {{ row.item.ultimo_acceso }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Roles asignados:</b>
                      <b-badge
                        v-for="role in row.item.roles_checklist"
                        :key="role.id"
                        class="mx-1"
                        :variant="getRoleVariant(role)"
                      >
                        {{ role.name }}
                      </b-badge>
                      <span v-if="row.item.roles_checklist.length <= 0" class="text-danger">
                        sin roles asignados
                      </span>
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Último acceso:</b>
                      {{
                        row.item.ultimo_acceso === 'Sin registro de ingreso'
                          ? row.item.ultimo_acceso
                          : $formatDate(row.item.ultimo_acceso)
                      }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Fecha de creación</b>
                      {{ $formatDate(row.item.fecha_creacion) }}
                    </b-list-group-item>

                    <b-list-group-item>
                      <b>Última modificación</b>
                      {{ $formatDate(row.item.ultima_modificacion) }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
              <b-col cols="12" md="6">
                <b-card
                  no-body
                  header="Permisos asignados"
                  header-bg-variant="warning"
                  header-text-variant="light"
                  border-variant="warning"
                  style="height: 500px; overflow: auto;"
                >
                  <b-list-group flush>
                    <b-list-group-item v-for="p in row.item.permissions" :key="p.id">
                      {{ p.name }}
                    </b-list-group-item>
                  </b-list-group>
                </b-card>
              </b-col>
            </b-row>

            <b-card no-body>
              <b-button
                variant="white"
                size="sm"
                class="btn-outline-black"
                @click="row.toggleDetails"
              >
                Cerrar detalle
              </b-button>
            </b-card>
          </b-card-body>
        </b-card>

        <!-- El botón de "ver como", únicamente lo puede usar el usuario con rol "admin" -->
      </template>
    </b-table>
    <loading v-else></loading>
    <div v-if="isThereUsers">
      <paginator
        aria-controls="usersTable"
        :disabled="!getAvailableList"
        show-path="userModule/getUsers"
        :resource="getUsersResource"
      ></paginator>
    </div>
    <!-- End Table -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilteredRow from '@/components/Common/Table/FilteredRow'
import XPasswordResetButton from '@/components/Shared/XPasswordResetButton.vue'

export default {
  name: 'UserList',

  components: {
    FilteredRow,
    XPasswordResetButton
  },

  props: {
    filter: {
      required: true
    }
  },

  created () {
    this.submit()
  },

  data () {
    return {
      fields: [
        { key: 'is_deleted', label: 'Habilitado', sortable: true },
        { key: 'persona.nombre_completo', label: 'Nombre', sortable: true },
        { key: 'email', label: 'Correo' },
        { key: 'roles_checklist', label: 'Roles', sortable: true },
        { key: 'show_details', label: 'Detalle' },
        { key: 'acciones', label: 'Acciones' }
      ],

      perPage: 10,

      currentPage: 1,

      totalRows: 0,

      filterOn: ['persona.nombre_completo', 'email']
    }
  },

  computed: {
    ...mapGetters('userModule', [
      'getUserList',
      'isAuthUserOrCloneUser',
      'getAvailableList',
      'isThereUsers',
      'getUsersResource'
    ])
  },

  methods: {
    async submit () {
      const { error, message } = await this.$store.dispatch('userModule/getUsers')

      if (error) this.$notify({ error, message }, 'Usuarios')
    },

    getRoleVariant (role) {
      if (role === 'admin') return 'success'
      if (role === 'subadmin') return 'info'
      if (role === 'inversionista') return 'warning'

      return 'primary'
    },

    filterFunction (row, filter) {
      if (!filter || filter === '') {
        return false
      }
      return row.email.toLowerCase().includes(filter.toLowerCase()) ||
        row.persona.nombre_completo.toLowerCase().includes(filter.toLowerCase())
    },

    onFiltered (filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    async viewAsUser (id) {
      await this.$store.dispatch('userModule/getViewAsUser', id)
      this.$router.push({ name: 'Dashboard' })
      window.scrollTo(0, 0)
    },

    emitOnGotItem () {
      this.$emit('on-got-item')
    }
  }
}
</script>
